var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var lualBodyClasses = ["the-coral-reef-south-west-childrens-research-appeal"];
var visualAppealsLoadMore = true;
var visualAppealsCustomHeightValues = {
  "the-coral-reef-south-west-childrens-research-appeal": {
    heightSm: "-800",
    heightMd: "-800",
    heightXl: "-1200"
  } 
}

if ($('body').hasClass('appeal-the-coral-reef-south-west-childrens-research-appeal')) {
  $(".headerText").append('<div id="background-bubbles"><div class="bubble x1"></div><div class="bubble x2"></div><div class="bubble x3"></div><div class="bubble x4"></div><div class="bubble x5"></div><div class="bubble x6"></div><div class="bubble x7"></div><div class="bubble x8"></div><div class="bubble x9"></div><div class="bubble x10"></div></div>');
  $(".shareWrapper").insertAfter("ul.commentsList");
}


setTimeout(() => {
  $(".homefeaturecategory-homeboxfullwidth").insertAfter(".homeFeedBox1");
}, 50);